var Cameraland = Cameraland || {};

Cameraland.ContentImages = class {
    constructor(options) {
        this.init = this.init.bind(this);
        this.imageCarousel = this.imageCarousel.bind(this);
        this.imageCompare = this.imageCompare.bind(this);

        this.options = jQuery.extend({}, {
            imageCarouselSelector: '.image-carousel',
            imageCompareSelector: '.image-compare',
            prevArrow: '<a href="#" class="slick-prev"><i class="ic-chevron-left"></i></a>',
            nextArrow: '<a href="#" class="slick-next"><i class="ic-chevron-right"></i></a>',
        }, options);

        this.init();
    }

    init() {
        this.imageCarousel();
        this.imageCompare();
    }

    imageCarousel() {
        jQuery(this.options.imageCarouselSelector).slick({
            prevArrow: this.options.prevArrow,
            nextArrow: this.options.nextArrow,
        });
    }

    imageCompare() {
        jQuery(this.options.imageCompareSelector).imageCompare();
    }

};

jQuery(function () {
    new Cameraland.ContentImages();
});